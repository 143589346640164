import { useContext } from 'react';
import { CircularProgress, Button, Stack, TextField, Link } from '@mui/material';
import './LoginForm.scss';
import { UserContext } from '../../../context/userContext';
import useLoginAuth from '../../../hooks/useLoginAuth';
import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import Logo from 'src/components/logo';

// ----------------------------------------------------------------------

const validationSchema = Yup.object({
  email: Yup.string()
    .email('Invalid email format')
    .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Invalid email format')
    .required('Required'),
  password: Yup.string().required('Required'),
});

const LoginForm = () => {
  const { setIsLoggedIn, setUser, setFetchingDataLoader } = useContext(UserContext);

  const handleLogInSubmit = async (values, { setSubmitting, setErrors }) => {
    try {
      await useLoginAuth({
        setIsLoggedIn,
        setUser,
        logInEmail: values.email,
        logInPassword: values.password,
        setFetchingDataLoader,
      });
    } catch (error) {
      if (error.message.includes('registered')) setErrors({ form: 'Incorrect email!' });
      else if (error.message.includes('password')) setErrors({ password: 'Incorrect password!' });
      else setErrors({ form: 'An error occurred while logging in.' });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{ email: '', password: '' }}
      validationSchema={validationSchema}
      onSubmit={handleLogInSubmit}
      validateOnChange={false}
    >
      {({ isSubmitting, handleChange, handleBlur, values, errors, touched, setErrors }) => (
        <Form>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Logo disabledLink />
          </div>

          <h2>Login</h2>
          <h4>Enter your credentials to access your account</h4>

          <div className="formField">
            <div className="label">Email</div>
            <Field
              as={TextField}
              variant="outlined"
              sx={{
                flexGrow: 1,
                width: '100%',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: touched.email && errors.email ? 'red' : '',
                  borderWidth: touched.email && errors.email ? '1.5px' : '',
                },
              }}
              inputProps={{
                sx: {
                  padding: '2px 10px',
                },
              }}
              type="email"
              name="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={() => setErrors([])}
              placeholder="Enter email"
            />

            <div style={{ height: '15px' }}>
              {touched.email && errors.email && <div style={{ color: 'red', fontSize: '12px' }}>{errors.email}</div>}
            </div>
          </div>

          <div className="formField">
            <div className="label">Password</div>
            <Field
              as={TextField}
              variant="outlined"
              placeholder="Enter password"
              onFocus={() => setErrors([])}
              sx={{
                flexGrow: 1,
                width: '100%',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: touched.password && errors.password ? 'red' : '',
                  borderWidth: touched.password && errors.password ? '1.5px' : '',
                },
              }}
              inputProps={{
                sx: {
                  padding: '2px 10px',
                },
              }}
              type="password"
              name="password"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            <div style={{ height: '15px' }}>
              {touched.password && errors.password && (
                <ErrorMessage name="password" component="div" style={{ color: 'red', fontSize: '12px' }} />
              )}
            </div>
          </div>

          <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ my: 2 }}>
            <Link
              variant="subtitle2"
              href="/identify"
              underline="hover"
              onClick={(e) => {
                console.log('ll');
              }}
            >
              Forgot password?
            </Link>
          </Stack>

          <div className="error">{errors.form || ' '}</div>
          <Button
            type="submit"
            fullWidth
            color="primary"
            variant="contained"
            disabled={isSubmitting}
            sx={{
              my: '1',
              fontWeight: 'bold',
              backgroundColor: '#028090',
              textTransform: 'capitalize',
              ':hover': {
                bgcolor: '#BEE6CE',
                color: 'black',
                fontWeight: 'bold',
              },
            }}
          >
            {isSubmitting ? <CircularProgress size={24} color="inherit" /> : 'Login'}
          </Button>
          <div className="termsContainer">
            This site is protected by reCAPTCHA and the Google Privacy Policy and Terms of Service apply.
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
