import { Stack, Typography, IconButton, Box, Avatar, Link } from '@mui/material';
import { fromUTCtoTimezone } from 'src/helpers/ConvertToTimezone';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import CopyButton from '../lead-components/CopyButton';
import CustomToolTip from '../lead-components/CustomToolTip';
import ColoredSelect from '../lead-components/ColoredSelect';
import { DraggableColumnHeader } from '../table/DragableColumnHeader';
import OTRLogo from '../../assets/OTRfavicon.ico';
import facebookLogo from '../../assets/facebookIcon.png';
import { RowCell } from './RowCell';
import './RowCell.scss';
import { IconsLabelCell } from './EmailCell';
import { TABLE_HEAD } from 'src/utils/dashboardColumnSettings';

export const getYesNoFromString = (metaValue, fallbackValue) => {
  if (metaValue?.toLowerCase().startsWith('yes') || metaValue?.toLowerCase() === 'true') {
    return 'Yes';
  } else if (metaValue?.toLowerCase().startsWith('no') || metaValue?.toLowerCase() === 'false') {
    return 'No';
  } else if (fallbackValue?.toLowerCase().startsWith('yes') || fallbackValue?.toLowerCase() === 'true') {
    return 'Yes';
  } else if (fallbackValue?.toLowerCase().startsWith('no') || fallbackValue?.toLowerCase() === 'false') {
    return 'No';
  } else {
    return '---';
  }
};

const renderDriverNameCell = (driverData, companyLogo) => {
  const driverFullName =
    driverData?.lead_meta_full_name || `${driverData.lead_first_name} ${driverData.lead_last_name}`;

  let avatarSrc;

  switch (driverData.lead_source) {
    case 'Recruiter':
      avatarSrc = companyLogo;
      break;
    case 'Facebook':
      avatarSrc = facebookLogo;
      break;
    case 'OpenOTR':
      avatarSrc = OTRLogo;
      break;
    default:
      avatarSrc = null;
  }
  return (
    <Stack direction="row" alignItems="center" spacing={2} sx={{ width: '100%' }}>
      {avatarSrc && (
        <Avatar
          sx={{
            height: '20px',
            width: '20px',
          }}
          src={avatarSrc}
          alt="photoURL"
        />
      )}
      <Typography
        variant="subtitle2"
        noWrap
        textTransform={'capitalize'}
        sx={{
          ml: '8px !important',
          width: '100%',
        }}
      >
        {driverFullName}
      </Typography>
      <CopyButton cellValue={driverFullName} />
    </Stack>
  );
};

export const getColumnRenderers = (handleOpenLeadCard, handleStatusChange, handleOpenNoteMenu, logo) => ({
  Date: (params) => (
    <RowCell onClick={() => handleOpenLeadCard(params.row.lead_id)} capitalized variant="subtitle2">
      {fromUTCtoTimezone({
        inputTime: params.row.lead_created_time,
        outputFormat: 'MM/DD/YYYY',
      })}
    </RowCell>
  ),

  Campaign: (params) => (
    <RowCell ellipsis onClick={() => handleOpenLeadCard(params.row.lead_id)}>
      {params.row.lead_campaign}
    </RowCell>
  ),

  Name: (params) => (
    <RowCell onClick={() => handleOpenLeadCard(params.row.lead_id)}>{renderDriverNameCell(params.row, logo)}</RowCell>
  ),

  Email: (params) => (
    <RowCell onClick={() => handleOpenLeadCard(params.row.lead_id)}>
      <IconsLabelCell duplicate={params.row.lead_duplicated_email} label={params.row.displayLeadEmail} />
    </RowCell>
  ),

  Exp: (params) => (
    <RowCell onClick={() => handleOpenLeadCard(params.row.lead_id)}>{params.row.displayLeadExperience}</RowCell>
  ),

  Phone: (params) =>
    params.row.displayLeadPhone && (
      <RowCell className="phoneLink">
        <IconsLabelCell
          duplicate={params.row.lead_duplicated_phone_number}
          label={params.row.displayLeadPhone}
          isLink
        />
      </RowCell>
    ),

  Status: (params) => (
    <div className="coloredSelect">
      <ColoredSelect
        initialValue={params.row.lead_status || 'not contacted'}
        onChange={(newStatus) => handleStatusChange(params.row.lead_id, newStatus)}
      />
    </div>
  ),

  Note: (params) => (
    <RowCell>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
        <CustomToolTip toolTipTitle={params.row.firstNoteText}>
          <Typography
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              marginRight: '8px',
              fontSize: '14px',
            }}
          >
            {params.row.firstNoteText}
          </Typography>
        </CustomToolTip>
        <IconButton
          size="small"
          color="inherit"
          sx={{ zIndex: 1, position: 'relative' }}
          onClick={(event) => handleOpenNoteMenu(event, params.row.lead_email)}
        >
          <NoteAddIcon sx={{ color: '#028090', zIndex: 5 }} size={30} />
        </IconButton>
        {params.row.notesNumber && (
          <Typography
            sx={{
              borderRadius: '20%',
              minWidth: '18px',
              height: '20px',
              backgroundColor: 'rgba(32, 101, 209, 0.6)',
              color: 'white',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontWeight: 'bold',
              fontSize: '12px',
            }}
          >
            <span>{params.row.notesNumber < 10 ? params.row.notesNumber : '9+'}</span>
          </Typography>
        )}
      </Box>
    </RowCell>
  ),

  'O/O': (params) => (
    <RowCell ellipsis onClick={() => handleOpenLeadCard(params.row.lead_id)}>
      {params.row.displayOwnerOpText}
    </RowCell>
  ),

  Model: (params) => (
    <RowCell ellipsis onClick={() => handleOpenLeadCard(params.row.lead_id)}>
      {params.row.displayLeadKindOfTruck}
    </RowCell>
  ),

  default: (params) => (
    <RowCell ellipsis onClick={() => handleOpenLeadCard(params.row.lead_id)}>
      {params.row[params.field]}
    </RowCell>
  ),
});

const getMetaValue = (metaValue, leadValue) => {
  return metaValue !== undefined ? metaValue || '---' : leadValue || '---';
};

const getYesNoFromBool = (metaValue, fallbackValue) =>
  metaValue === 'true' || fallbackValue === 'true' ? 'Yes' : metaValue || fallbackValue ? 'No' : '---';

export const mapDriversData = (driversData) => {
  return driversData.map((row) => {
    const {
      lead_email,
      lead_id,
      lead_experience,
      lead_are_you_an_owner_op,
      lead_owner_op = '---',
      lead_phone_number,
      lead_status,
      lead_created_time,
      driverNotes = [],
      lead_meta_email,
      lead_meta_phone_number,
      lead_meta_experience,
      lead_meta_owner_op = '---',
      lead_meta_are_you_an_owner_op,
      lead_meta_state,
      lead_state,
      lead_status_modified,
      lead_meta_lp_experience,
      lead_meta_interested_in,
      lead_interested_in,
      lead_meta_trailer_type,
      lead_kind_of_trailer,
      lead_meta_truck_preference_1,
      lead_meta_truck_preference_2,
      lead_meta_violation,
      lead_meta_accident,
      lead_meta_drug_test,
      lead_drug_test,
      lead_meta_clean_mvr,
      lead_clean_mvr,
      lead_meta_clearing_house,
      lead_clearing_house,
      lead_recent_dui_failed_sap,
      lead_meta_recent_dui_failed_sap,
      lead_meta_home_time,
      lead_meta_start_time,
      lead_meta_company_name,
      lead_meta_employment,
      lead_meta_position,
      lead_meta_why,
      lead_meta_miles,
      lead_meta_endorsments,
      lead_endorsements,
      lead_meta_note,
      lead_campaign = '---',
      lead_meta_pay,
      lead_duplicated_phone_number,
      lead_duplicated_email,
      lead_source,
      lead_meta_full_name,
      lead_first_name,
      lead_last_name,
    } = row;

    const displayLeadEmail = lead_meta_email?.trim().toLowerCase() || lead_email?.trim().toLowerCase() || '';
    const displayLeadPhone = lead_meta_phone_number || lead_phone_number || '';
    const displayLeadExperience = lead_meta_experience || lead_experience || '';
    const displayLeadKindOfTruck = getMetaValue(lead_meta_owner_op, lead_owner_op);
    const displayOwnerOpText = getYesNoFromString(lead_meta_are_you_an_owner_op, lead_are_you_an_owner_op);

    const firstNoteText =
      driverNotes && driverNotes.length !== 0 && driverNotes[0]?.note_text ? driverNotes[0]?.note_text : '';
    const notesNumber = driverNotes && driverNotes.length !== 0 && driverNotes.length;

    const remainingFields = {
      id: lead_id,
      State: lead_meta_state || lead_state || '---',
      'Last Status Update':
        lead_status === 'not contacted'
          ? fromUTCtoTimezone({ inputTime: lead_created_time })
          : fromUTCtoTimezone({ inputTime: lead_status_modified }) || '---',
      'LP Experience': getYesNoFromBool(lead_meta_lp_experience),
      'Interested In': getMetaValue(lead_meta_interested_in, lead_interested_in),
      PTT: getMetaValue(lead_meta_trailer_type, lead_kind_of_trailer),
      'Truck Preferences':
        ((lead_meta_truck_preference_1 || lead_meta_truck_preference_2) &&
          `${lead_meta_truck_preference_1}${lead_meta_truck_preference_2 && ' | '}${lead_meta_truck_preference_2}`) ||
        '---',
      Violation: getYesNoFromBool(lead_meta_violation),
      Accident: getYesNoFromBool(lead_meta_accident),
      'Drug Test': getMetaValue(lead_meta_drug_test, lead_drug_test),
      MVR: getMetaValue(lead_meta_clean_mvr, lead_clean_mvr),
      'Clearing house': getMetaValue(lead_meta_clearing_house, lead_clearing_house),
      'DUI / SAP': getMetaValue(lead_meta_recent_dui_failed_sap, lead_recent_dui_failed_sap),
      'Home Time': lead_meta_home_time || '---',
      'Start Time': lead_meta_start_time || '---',
      'Previous Company': lead_meta_company_name || '---',
      'Previous Employment': getYesNoFromBool(lead_meta_employment),
      'Previous Position': lead_meta_position || '---',
      Why: lead_meta_why || '---',
      'Previous Miles': lead_meta_miles || '---',
      'Previous Pay': lead_meta_pay || '---',
      Endorsements: getMetaValue(lead_meta_endorsments, lead_endorsements),
      Summary: lead_meta_note || '---',
    };

    return {
      lead_id,
      lead_campaign,
      lead_created_time,
      lead_duplicated_email,
      lead_duplicated_phone_number,
      lead_status,
      lead_email,
      lead_source,
      lead_meta_full_name,
      lead_first_name,
      lead_last_name,
      lead_experience,
      lead_are_you_an_owner_op,
      lead_owner_op,
      lead_meta_email,
      lead_meta_phone_number,
      lead_meta_experience,
      lead_meta_owner_op,
      lead_meta_are_you_an_owner_op,
      lead_meta_state,
      lead_state,
      lead_status_modified,
      lead_meta_lp_experience,
      lead_meta_interested_in,
      lead_interested_in,
      lead_meta_trailer_type,
      lead_kind_of_trailer,
      lead_meta_truck_preference_1,
      lead_meta_truck_preference_2,
      lead_meta_violation,
      lead_meta_accident,
      lead_meta_drug_test,
      lead_drug_test,
      lead_meta_clean_mvr,
      lead_clean_mvr,
      lead_meta_clearing_house,
      lead_clearing_house,
      lead_recent_dui_failed_sap,
      lead_meta_recent_dui_failed_sap,
      lead_meta_home_time,
      lead_meta_start_time,
      lead_meta_company_name,
      lead_meta_employment,
      lead_meta_position,
      lead_meta_why,
      lead_meta_miles,
      lead_meta_endorsments,
      lead_endorsements,
      lead_meta_note,
      driverNotes,
      displayLeadEmail,
      displayLeadPhone,
      displayLeadExperience,
      displayLeadKindOfTruck,
      displayOwnerOpText,
      firstNoteText,
      notesNumber,
      ...remainingFields,
    };
  });
};

export const mapColumnData = (
  columns,
  handleOpenLeadCard,
  handleStatusChange,
  handleOpenNoteMenu,
  moveColumn,
  logo
) => {
  const columnRenderers = getColumnRenderers(handleOpenLeadCard, handleStatusChange, handleOpenNoteMenu, logo);

  const mappedColumnsData = columns.map((col, index) => {
    const defaultColumn = TABLE_HEAD.find((defaultCol) => defaultCol.label === col.label);

    return {
      id: col.id,
      field: col.label,
      headerName: col.label,
      width: col.width,
      maxWidth: defaultColumn.maxWidth ?? 300,
      minWidth: defaultColumn.minWidth ?? 100,
      sortable: false,
      alignRight: defaultColumn.alignRight,
      headerAlign: 'center',
      disableColumnMenu: true,
      renderCell: columnRenderers[col.label] || columnRenderers.default,
      renderHeader: (params) => <DraggableColumnHeader column={params.colDef} index={index} moveColumn={moveColumn} />,
    };
  });

  return mappedColumnsData;
};

//Turn label:boolean into object
export const getColumnSettingsFromSet = (values) => {
  return Object.keys(values)
    .map((key) => {
      if (!values[key]) return null;
      const column = TABLE_HEAD.find((t) => t.label === key);
      if (column) {
        const { id, label, width } = column;
        return { id, label, width };
      }
      return null;
    })
    .filter(Boolean);
};
export const getNewColumnsData = (newValues, oldValues) => {
  //filter out removed columns
  const filteredOldValues = oldValues.filter((col) => newValues[col.label] !== false);

  const newColumns = Object.keys(newValues)
    .filter((key) => newValues[key]) // remove unchecked with false value
    .filter((key) => !oldValues.some((col) => col.label === key)) // remove not changed
    .map((key) => {
      const column = TABLE_HEAD.find((t) => t.label === key);
      return column ? { id: column.id, label: column.label, width: column.width } : null;
    }) //get data for new cols
    .filter(Boolean);

  return [...filteredOldValues, ...newColumns];
};
