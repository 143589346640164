import React, { useState } from 'react';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import CheckIcon from '@mui/icons-material/Check';
import { Box } from '@mui/material';
import CustomToolTip from './CustomToolTip';

const CopyButton = ({ cellValue }) => {
  const [isCopyClicked, setIsCopyClicked] = useState(false);

  const copyToClipboard = async (textToCopy) => {
    setIsCopyClicked(true);
    try {
      await navigator.clipboard.writeText(textToCopy);
      let timeoutId = setTimeout(() => {
        setIsCopyClicked(false);
      }, 1000);
      return () => clearTimeout(timeoutId);
    } catch (err) {
      console.error('Failed to copy: ', err);
      let timeoutId = setTimeout(() => {
        setIsCopyClicked(false);
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
  };
  return (
    <Box
      className="hoveredButton"
      sx={{
        position: 'relative',
        borderRadius: '50%',

        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: '30px',
        height: '30px',
        marginLeft: 'auto',
        zIndex: 1,
        overflow: 'hidden',

        '&:hover': {
          '& > .hoverEffect': {
            opacity: 1,
          },
        },
      }}
      onClick={(e) => {
        e.stopPropagation();
        copyToClipboard(cellValue);
      }}
    >
      <Box
        className="hoverEffect"
        sx={{
          position: 'absolute',
          inset: 0,
          borderRadius: 'inherit',
          background: 'radial-gradient(circle, rgba(255, 192, 203, 0) 50%, green 100%)',
          opacity: 0,
          transition: 'opacity 0.3s',
          zIndex: -1,
        }}
      />
      <CustomToolTip toolTipTitle={isCopyClicked ? 'Copied!' : 'Copy'} placement={'top'} offset={[0, 0]}>
        <Box display="flex" alignItems="center">
          {isCopyClicked && <CheckIcon color="success" />}
          {!isCopyClicked && (
            <ContentPasteIcon
              fontSize="small"
              sx={{
                zIndex: 1,
                color: 'rgb(2, 128, 144)',
              }}
            />
          )}
        </Box>
      </CustomToolTip>
    </Box>
  );
};

export default CopyButton;
