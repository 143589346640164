import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { AppBar, Toolbar, IconButton, Box, Stack, useScrollTrigger, Slide, Typography } from '@mui/material';
// utils
import { bgBlur } from '../../../utils/cssStyles';
// components
import Iconify from '../../../components/iconify';
//
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import NotificationsPopover from './NotificationsPopover';
import { useEffect, useState } from 'react';
import { BorderBottom } from '@mui/icons-material';

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 64;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  ...theme.mixins.toolbar,
  ...bgBlur({ color: theme.palette.background.default }),
  boxShadow: 'none',
  transition: theme.transitions.create(['top'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  // boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.25)',
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 1),
  },
  justifyContent: 'space-between',
}));

// ----------------------------------------------------------------------

function HideOnScroll(props) {
  const { children } = props;
  // Use the window as the scroll target
  const trigger = useScrollTrigger({ threshold: 50 });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

Header.propTypes = {
  onOpenNav: PropTypes.func.isRequired,
};

export default function Header({ onOpenNav }) {
  // const [show, setShow] = useState(true);
  // const controlNavbar = () => {
  //   if (window.scrollY > 100) {
  //     setShow(false);
  //   } else {
  //     setShow(true);
  //   }
  // };

  // useEffect(() => {
  //   if (hideOnScroll) {
  //     window.addEventListener('scroll', controlNavbar);
  //     return () => {
  //       window.removeEventListener('scroll', controlNavbar);
  //     };
  //   }
  // }, [hideOnScroll]);
  const location = useLocation();
  const pathname = location.pathname;
  const lastQuery = pathname.split('/').pop() || '';

  return (
    <HideOnScroll>
      <StyledRoot>
        <StyledToolbar>
          <IconButton
            onClick={onOpenNav}
            sx={{
              mr: 1,
              color: 'text.primary',
              // display: { lg: 'none' },
            }}
          >
            <Iconify icon="eva:menu-2-fill" />
          </IconButton>
          {/* <Searchbar /> */}
          {/* <Box sx={{ flexGrow: 1 }} /> */}
          <Typography variant="h4" sx={{ color: 'black', textTransform: 'capitalize' }}>
            {lastQuery}
          </Typography>
          <Stack
            direction="row"
            alignItems="center"
            spacing={{
              xs: 0.5,
              sm: 1,
            }}
          >
            {/* <LanguagePopover /> */}
            {/* <NotificationsPopover /> */}
            <AccountPopover />
          </Stack>
        </StyledToolbar>
      </StyledRoot>
    </HideOnScroll>
  );
}
