import React, { useState } from 'react';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, Slide } from '@mui/material';
import { styled } from '@mui/material/styles';
import useUpdateLeadCard from 'src/hooks/useUpdateLeadCard';
import SnackbarNotification from './SnackBarNotification';
import LeadCardContent from './LeadCardContent';
import LeadCardHeader from './LeadCardHeader';
import LeadCardPreview from './LeadCardPreview';
import { Form, Formik, useFormikContext } from 'formik';
import useDeleteLead from 'src/hooks/useDeleteLead';
import DeleteLeadPopover from './DeleteLeadPopover';
import PrevNextButtons from '../prev-next-buttons/PrevNextButtons';
import { currentDateToTimezone, dayjs } from 'src/helpers/ConvertToTimezone';
import CloseModalIcon from '../globalCloseIcon/closeModalIcon';
import { getYesNoFromString } from '../dashboard-table/helpers';
import LeadTimeTable from './LeadTimeTable';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const SaveButton = styled(({ isSubmitting, bgColor, hoverBgColor, marginLeft, ...otherProps }) => (
  <Button {...otherProps} />
))(({ theme, isSubmitting, marginLeft = 0, bgColor = '#4cbd29', hoverBgColor = theme.palette.success.dark }) => ({
  color: isSubmitting ? theme.palette.action.disabled : theme.palette.common.white,
  backgroundColor: isSubmitting ? theme.palette.action.disabledBackground : bgColor,
  padding: '6px 16px',
  fontSize: '0.875rem',
  fontWeight: 800,
  minWidth: '64px',
  boxShadow: `0 2px 4px ${theme.palette.grey[500]}`,
  marginRight: '30px',
  marginLeft: marginLeft,
  width: '100px',
  transition: theme.transitions.create(['background-color', 'box-shadow', 'transform'], {
    duration: theme.transitions.duration.short,
    easing: theme.transitions.easing.easeInOut,
  }),
  pointerEvents: isSubmitting ? 'none' : 'auto',
  '&:hover': {
    backgroundColor: isSubmitting ? theme.palette.action.disabledBackground : hoverBgColor,
    boxShadow: `0 4px 8px ${theme.palette.grey[700]}`,
    transform: 'translateY(-2px)',
  },
  '&:active': {
    boxShadow: `0 1px 2px ${theme.palette.grey[700]}`,
    transform: 'translateY(1px)',
  },
  borderRadius: '8px',
  textTransform: 'none',
  letterSpacing: 'normal',
}));

export const CancelButton = styled(Button)(({ theme, width = '100px' }) => ({
  color: theme.palette.common.white,
  backgroundColor: 'red',
  padding: '6px 16px',
  fontSize: '0.875rem',
  minWidth: '64px',
  marginRight: '30px',
  width: width,
  boxShadow: `0 2px 4px ${theme.palette.grey[500]}`,
  transition: theme.transitions.create(['background-color', 'box-shadow', 'transform'], {
    duration: theme.transitions.duration.short,
    easing: theme.transitions.easing.easeInOut,
  }),
  '&:hover': {
    backgroundColor: theme.palette.error.dark,
    boxShadow: `0 3px 6px ${theme.palette.grey[700]}`,
    transform: 'scale(0.95)',
  },
  '&:active': {
    boxShadow: `0 1px 2px ${theme.palette.grey[700]}`,
    transform: 'scale(0.90)',
  },
  borderRadius: '8px',
  textTransform: 'none',
  letterSpacing: 'normal',
  fontWeight: 500,
}));

const DialogActionsWithFormik = ({ handleCloseLeadCard, driverSource, setOpenDeleteLeadCard }) => {
  const { isSubmitting, handleSubmit, resetForm } = useFormikContext();
  const displayDeleteButton = driverSource === 'Recruiter' ? true : false;
  return (
    <DialogActions
      sx={{
        justifyContent: 'space-between',
      }}
    >
      {displayDeleteButton && (
        <Box ml="30px">
          <CancelButton
            width="100%"
            onClick={(e) => {
              setOpenDeleteLeadCard(true);
            }}
          >
            Delete Lead
          </CancelButton>
        </Box>
      )}
      <Box
        sx={{
          ml: 'auto',
        }}
      >
        <CancelButton onClick={() => handleCloseLeadCard(resetForm)}>Cancel</CancelButton>
        <SaveButton type="button" disabled={isSubmitting} isSubmitting={isSubmitting} onClick={handleSubmit}>
          {isSubmitting ? <CircularProgress size={24} /> : 'Save'}
        </SaveButton>
      </Box>
    </DialogActions>
  );
};

const DriverCardModal = ({
  currentDriver,
  setCurrentDriver,
  allRecruiters,
  handleStatusChange,
  openLeadCard,
  setOpenLeadCard,
  cardFieldsState,
  setCardFieldsState,
  handleOpenUpdateMenu,
  isUpdating,
  isUpdatingIndex,
  isDeletingIndex,
  isDeletingFlag,
  setDeleteSelectedIdx,
  openDeletePopover,
  handleOpenNoteAddMenu,
  setAllDrivers,
  allDrivers,
  user,
}) => {
  const leadFullName =
    currentDriver?.lead_meta_full_name || `${currentDriver.lead_first_name} ${currentDriver.lead_last_name}`;

  const [isNextDisabled, setIsNextDisabled] = useState(false);
  const [isPreviousDisabled, setIsPreviousDisabled] = useState(true);
  const [openNotification, setOpenNotification] = useState(false);
  const [openTab, setOpenTab] = useState(1);
  const [deleteLeadLoading, setDeleteLeadLoading] = useState(false);
  const [openDeleteLeadCard, setOpenDeleteLeadCard] = useState(false);
  const [notificationHandler, setNotificationHandler] = useState({
    title: '',
    severity: '',
  });

  const closeDeletePopover = () => {
    setOpenDeleteLeadCard(false);
  };

  const handleTabClick = (tabNumber) => {
    setOpenTab(tabNumber);
  };
  const handleDeleteLead = async () => {
    const bodyData = { lead_id: currentDriver.lead_id };
    setDeleteLeadLoading(true);

    const notificationTitle = (
      <>
        You have successfully deleted the lead <strong>{leadFullName}</strong>
      </>
    );
    try {
      await useDeleteLead(bodyData);
      setAllDrivers((prevDrivers) => prevDrivers.filter((driver) => driver.lead_id !== currentDriver.lead_id));
      setOpenLeadCard(false);
      setNotificationHandler({
        title: notificationTitle,
        severity: 'success',
      });
    } catch (error) {
      setNotificationHandler({
        title: 'Something went wrong, the Lead was not deleted. Please try again',
        severity: 'error',
      });
    }
    setDeleteLeadLoading(false);
    setOpenNotification(true);
    closeDeletePopover();
  };

  const handleChange = (e) => {
    setCardFieldsState({
      ...cardFieldsState,
      [e.target.name]: e.target.value,
    });
  };

  const handleCloseLeadCard = (resetForm) => {
    setOpenLeadCard(false);
    resetForm();
  };

  const handleHideNotification = () => {
    setOpenNotification((prev) => !prev);
  };

  const handleSaveLeadCard = async (values, { setSubmitting, resetForm }) => {
    const bodyData = {
      lead_id: currentDriver.lead_id,
      company_id: user.company_id,
      ...values,
    };
    setSubmitting(true);
    let updatedBodyData = {};
    let timeLogEntry = null;

    const updatedDrivers = allDrivers.map((driver) => {
      if (driver.lead_id === currentDriver.lead_id) {
        updatedBodyData = Object.keys(bodyData).reduce((newObj, key) => {
          if (key !== 'lead_id' && key !== 'company_id') {
            newObj[`lead_${key}`] = bodyData[key];
          }
          return newObj;
        }, {});
        updatedBodyData.lead_status_modified = currentDateToTimezone();

        if (values.status && values.status !== currentDriver.lead_status) {
          const timestamp = [dayjs(currentDateToTimezone()).format('YYYY-MM-DD HH:mm:ss')];
          timeLogEntry = {
            [timestamp]: {
              recruiter_id: user.recruiter_id ?? user.company_id,
              event_name: 'status',
              content: values.status,
            },
          };
        }

        return {
          ...driver,
          ...updatedBodyData,
          lead_event_log: timeLogEntry
            ? {
                ...driver.lead_event_log,
                ...timeLogEntry,
              }
            : driver.lead_event_log,
        };
      }
      return driver;
    });
    try {
      await useUpdateLeadCard(bodyData);
      setNotificationHandler({
        title: 'You have successfully updated the Lead Card',
        severity: 'success',
      });
      setOpenNotification(true);
      setAllDrivers(updatedDrivers);

      setCurrentDriver((prev) => ({
        ...prev,
        ...updatedBodyData,
        lead_event_log: timeLogEntry
          ? {
              ...prev.lead_event_log,
              ...timeLogEntry,
            }
          : prev.lead_event_log,
      }));
      handleCloseLeadCard(resetForm);
    } catch (error) {
      setNotificationHandler({
        title: 'Something went wrong, your changes were not saved',
        severity: 'error',
      });
      setOpenNotification(true);
      resetForm();
      timeLogEntry = null;
    }
  };

  const handleLeadChange = (direction) => {
    const currentIndex = allDrivers.findIndex((driver) => driver.lead_id === currentDriver.lead_id);
    let newIndex;

    if (direction === '+') {
      newIndex = currentIndex + 1;
    } else if (direction === '-') {
      newIndex = currentIndex - 1;
    }

    if (newIndex >= 0 && newIndex < allDrivers.length) {
      setCurrentDriver(allDrivers[newIndex]);

      setIsPreviousDisabled(newIndex === 0);
      setIsNextDisabled(newIndex === allDrivers.length - 1);
    }
  };

  const handleKeyDown = (event) => {
    console.log(event.key);
    // const activeElement = document.activeElement;
    // if (!['INPUT', 'TEXTAREA', 'SELECT'].includes(activeElement.tagName)) {
    //   if (event.key === 'ArrowLeft') {
    //     handleLeadChange('-');
    //   } else if (event.key === 'ArrowRight') {
    //     handleLeadChange('+');
    //   }
    // }
  };

  const ownerOperatorValue = () => {
    const value = getYesNoFromString(
      currentDriver.lead_meta_are_you_an_owner_op,
      currentDriver.lead_are_you_an_owner_op
    );

    if (value === 'Yes') {
      return 'true';
    } else if (value === 'No') {
      return 'false';
    } else {
      return null;
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          meta_full_name:
            currentDriver?.lead_meta_full_name ||
            `${currentDriver?.lead_first_name} ${currentDriver?.lead_last_name}` ||
            '',
          meta_phone_number: currentDriver?.lead_meta_phone_number || currentDriver?.lead_phone_number || '',
          meta_email: currentDriver?.lead_meta_email || currentDriver?.lead_email || '',
          meta_state: currentDriver?.lead_meta_state || currentDriver?.lead_state || '',
          meta_are_you_an_owner_op: ownerOperatorValue(),
          meta_owner_op: currentDriver?.lead_meta_owner_op || currentDriver?.lead_owner_op || '',
          meta_endorsments: currentDriver?.lead_meta_endorsments || currentDriver?.lead_endorsements || '',
          meta_experience: currentDriver?.lead_meta_experience || currentDriver?.lead_experience || '',
          meta_violation: currentDriver?.lead_meta_violation || '',
          meta_accident: currentDriver?.lead_meta_accident || '',
          meta_lp_experience: currentDriver?.lead_meta_lp_experience || currentDriver?.lead_experience || '',
          meta_home_time: currentDriver?.lead_meta_home_time || '',
          meta_start_time: currentDriver?.lead_meta_start_time || '',
          meta_truck_preference_1: currentDriver?.lead_meta_truck_preference_1 || '',
          meta_truck_preference_2: currentDriver?.lead_meta_truck_preference_2 || '',
          meta_trailer_type: currentDriver?.lead_meta_trailer_type || currentDriver?.lead_kind_of_trailer || '',
          meta_employment: currentDriver?.lead_meta_employment || '',
          meta_company_name: currentDriver?.lead_meta_company_name || '',
          meta_position: currentDriver?.lead_meta_position || currentDriver?.lead_interested_in || '',
          meta_why: currentDriver?.lead_meta_why || '',
          meta_miles: currentDriver?.lead_meta_miles || '',
          meta_pay: currentDriver?.lead_meta_pay || '',
          meta_drug_test: currentDriver?.lead_meta_drug_test || currentDriver?.lead_drug_test || '',
          meta_note: currentDriver?.lead_meta_note || '',
          meta_clearing_house: currentDriver?.lead_meta_clearing_house || currentDriver?.lead_clearing_house || '',
          meta_recent_dui_failed_sap:
            currentDriver?.lead_meta_recent_dui_failed_sap || currentDriver?.lead_recent_dui_failed_sap || '',
          meta_clean_mvr: currentDriver?.lead_meta_clean_mvr || currentDriver?.lead_clean_mvr || '',
          status: currentDriver?.lead_status || 'not contacted',
          recruiter_id: currentDriver.lead_recruiter_id || '',
          driver_position: currentDriver?.lead_driver_position || 'driver_position',
        }}
        onSubmit={handleSaveLeadCard}
        enableReinitialize
      >
        {({ touched, resetForm }) => (
          <Form onKeyDown={handleKeyDown} style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            <Dialog
              open={openLeadCard}
              TransitionComponent={Transition}
              PaperProps={{
                sx: { width: '100%', maxWidth: 'none', height: '100%', overflow: 'visible', position: 'relative' },
              }}
              onClose={() => handleCloseLeadCard(resetForm)}
              aria-describedby="alert-dialog-slide-description"
            >
              <CloseModalIcon handleClose={handleCloseLeadCard} resetForm={resetForm} />
              <LeadCardHeader leadFullName={leadFullName} handleTabClick={handleTabClick} openTab={openTab} />

              <DialogContent
                className="modern-scrollbar"
                sx={{
                  pl: '40px',
                  pr: '40px',
                  pb: 0,
                }}
              >
                <PrevNextButtons
                  handleLeadChange={handleLeadChange}
                  isPreviousDisabled={isPreviousDisabled}
                  isNextDisabled={isNextDisabled}
                />

                {openTab === 1 && currentDriver && (
                  <LeadCardContent
                    currentDriver={currentDriver}
                    allRecruiters={allRecruiters}
                    handleStatusChange={handleStatusChange}
                    cardFieldsState={cardFieldsState}
                    handleChange={handleChange}
                    handleOpenUpdateMenu={handleOpenUpdateMenu}
                    isUpdating={isUpdating}
                    isUpdatingIndex={isUpdatingIndex}
                    isDeletingIndex={isDeletingIndex}
                    isDeletingFlag={isDeletingFlag}
                    setDeleteSelectedIdx={setDeleteSelectedIdx}
                    openDeletePopover={openDeletePopover}
                    handleOpenNoteAddMenu={handleOpenNoteAddMenu}
                    touched={touched}
                    isUserAdmin={!user.recruiter_id}
                  />
                )}
                {openTab === 2 && <LeadCardPreview currentDriver={currentDriver} />}
                {openTab === 3 && (
                  <LeadTimeTable currentDriver={currentDriver} allRecruiters={allRecruiters} user={user} />
                )}
              </DialogContent>
              <DialogActionsWithFormik
                handleCloseLeadCard={handleCloseLeadCard}
                driverSource={currentDriver.lead_source}
                setOpenDeleteLeadCard={setOpenDeleteLeadCard}
              />
            </Dialog>
          </Form>
        )}
      </Formik>
      <DeleteLeadPopover
        closeDeletePopover={closeDeletePopover}
        handleDeleteLead={handleDeleteLead}
        deleteLeadLoading={deleteLeadLoading}
        openDeleteLeadCard={openDeleteLeadCard}
        leadFullName={leadFullName}
      />
      <SnackbarNotification
        showNotification={openNotification}
        notificationHandler={notificationHandler}
        handleHideNotification={handleHideNotification}
      />
    </>
  );
};

export default DriverCardModal;
