import CopyButton from '../lead-components/CopyButton';
import { DuplicateLeadIcon } from '../lead-components/DuplicateLeadIcon';
import { Link } from '@mui/material';

export const IconsLabelCell = ({ duplicate, label, isLink = false }) => {
  return (
    <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
      {duplicate && <DuplicateLeadIcon />}
      {isLink ? (
        <Link
          href={`tel:+${label.replace(/[^0-9+]/g, '')}`}
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            textDecoration: 'none',
            color: 'inherit',
            display: 'inline',
            fontWeight: 600,
            '&:hover': {
              color: 'green',
              textDecoration: 'underline',
            },
          }}
        >
          {label}
        </Link>
      ) : (
        <div
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontSize: '14px',
          }}
        >
          {label}
        </div>
      )}
      {label && <CopyButton cellValue={label} />}
    </div>
  );
};
