import React, { useState, useEffect } from 'react';
import { FormControl, Select, MenuItem } from '@mui/material';
import { styled } from '@mui/system';

export const selectColorOptions = {
  'not contacted': 'rgb(232 234 237)',
  'attempting contact': 'rgb(230 207 242)',
  'voice mail 1': 'rgb(230 207 242)',
  'voice mail 2': 'rgb(230 207 242)',
  'no response': 'rgb(117 56 0)',
  'follow up': 'rgb(230 207 242)',
  '2nd follow up': 'rgb(230 207 242)',
  processing: 'rgb(191 225 246)',
  approved: 'rgb(212 237 188)',
  hired: 'rgb(17 115 75)',
  'not qualified': 'rgb(61 61 61)',
  'driver not interested': 'rgb(255 229 160)',
  'not interested/not an o/o': 'rgb(255 229 160)',
  'not interested/equipment': 'rgb(255 229 160)',
  'not interested/running areas': 'rgb(255 229 160)',
  'not interested/pay': 'rgb(255 229 160)',
  'not interested/fixed costs on LP': 'rgb(255 229 160)',
  'not interested/benefits': 'rgb(255 229 160)',
  'not interested/hometime': 'rgb(255 229 160)',
  'not interested/rider-pet policy': 'rgb(255 229 160)',
  'not interested/not ready for change': 'rgb(255 229 160)',
  'not interested/escrow': 'rgb(255 229 160)',
  'pass/no CDL': 'rgb(255 207 201)',
  'pass/MVR': 'rgb(255 207 201)',
  'pass/DUI': 'rgb(255 207 201)',
  'pass/not enough experience': 'rgb(255 207 201)',
  'pass/positive drug test or refusal': 'rgb(255 207 201)',
  'pass/work history': 'rgb(255 207 201)',
  'pass/too old': 'rgb(255 207 201)',
  'pass/SAP': 'rgb(255 207 201)',
  'pass/out of hiring area': 'rgb(255 207 201)',
  'pass/language barrier': 'rgb(255 207 201)',
  'number not valid': 'rgb(33 90 108)',
  'spam/fake lead': 'rgb(104 148 166)',
  'pass/criminal history': 'rgb(90 50 134)',
};

const CustomSelect = styled(Select)({
  '&& .MuiMenu-paper .MuiList-root': {
    '&::-webkit-scrollbar': {
      width: '12px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  },
});

function isColorDark(rgbColor) {
  if (!rgbColor || !rgbColor.includes('rgb')) {
    return false;
  }

  const rgb = rgbColor.replace('rgb(', '').replace(')', '').split(' ').map(Number);
  const r = rgb[0];
  const g = rgb[1];
  const b = rgb[2];
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;
  return brightness < 150;
}

function ColoredSelect({ initialValue, onChange }) {
  const [status, setStatus] = useState(initialValue);

  useEffect(() => {
    if (!initialValue) return;
    setStatus(initialValue);
  }, [initialValue]);

  const handleChange = (event) => {
    const newStatus = event.target.value;
    setStatus(newStatus);
    onChange(newStatus);
  };

  return (
    <FormControl variant="outlined" fullWidth sx={{ width: '100%', p: 0 }}>
      <CustomSelect
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={status}
        onChange={handleChange}
        label=""
        sx={{
          width: '100%',
          backgroundColor: selectColorOptions[status],
          color: isColorDark(selectColorOptions[status]) ? 'white' : 'black',
        }}
      >
        {Object.keys(selectColorOptions).map((key) => (
          <MenuItem
            key={key}
            value={key}
            selected={key === status}
            disabled={key === 'not contacted'}
            sx={{
              py: '0',
              backgroundColor: key === status ? 'selectedColor' : selectColorOptions[key],
              color: isColorDark(key === status ? 'selectedColor' : selectColorOptions[key]) ? 'white' : 'black',
              '&:hover': {
                backgroundColor: (theme) => theme.palette.action.hover,
                color: 'black',
              },
            }}
          >
            {key}
          </MenuItem>
        ))}
      </CustomSelect>
    </FormControl>
  );
}

export default ColoredSelect;
