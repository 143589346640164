import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Divider, Stack, Button } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Logo from '../components/logo';
import Iconify from '../components/iconify';
// sections
import { LoginForm } from '../sections/auth/login';
import './LoginPage.scss';

// ----------------------------------------------------------------------

// const StyledFormContainer = styled('div')(({ theme }) => ({

//   boxShadow: theme.customShadows.card,
//   backgroundColor: theme.palette.background.default,
// }));

// ----------------------------------------------------------------------

export default function LoginPage() {
  const mdUp = useResponsive('up', 'md');

  return (
    <div className="container">
      <div className="formContainer">
        <div className="form">
          <LoginForm />
        </div>
      </div>
      <div className="imageContainer" />
    </div>
  );
}
