import React, { useCallback, useMemo } from 'react';

import { Typography, Box, CircularProgress } from '@mui/material';

import { mapDriversData } from './helpers';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DataGrid } from '@mui/x-data-grid';
import './DashboardTable.scss';

const debounce = (func, delay) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => func(...args), delay);
  };
};
const DashboardTable = ({
  allDrivers,
  mappedColumns,
  setColumns,
  fetchingDataLoader,
  isNotFound,
  currentSearchedStatus,
  filterName,
  handleChangeRowsPerPage,
  handleChangePage,
  rowsPerPage,
  totalLeads,
  columnsData,
  page,
}) => {
  const columnDrivers = useMemo(() => mapDriversData(allDrivers), [allDrivers]);

  const saveToLocalStorage = useCallback(
    debounce((updatedColumns) => {
      localStorage.setItem('dashboardColumnSettings', JSON.stringify(updatedColumns));
    }, 200),
    []
  );

  const handleColumnResize = (params) => {
    const column = { label: params.colDef.field, width: params.width };

    const updatedColumns = columnsData.map((col) =>
      col.label === column.label ? { ...col, width: column.width } : col
    );

    setColumns(updatedColumns);
    saveToLocalStorage(updatedColumns);
  };

  return (
    <>
      {fetchingDataLoader ? (
        <>
          {allDrivers.length !== 0 ? (
            <Box sx={{ height: 'calc(100vh - 220px)', overflow: 'auto' }}>
              <DndProvider backend={HTML5Backend}>
                <DataGrid
                  getRowClassName={(params) => (params.row.lead_duplicated_phone_number ? 'duplicate-row' : '')}
                  sx={{
                    '& .duplicate-row': {
                      backgroundColor: 'lightyellow',
                    },
                    '& .MuiDataGrid-cell:focus': {
                      outline: 'none',
                    },
                    '& .duplicate-row:hover': {
                      backgroundColor: 'rgba(255, 255, 224, 0.5)',
                    },
                    '& .MuiDataGrid-row.Mui-selected': {
                      outline: 'none',
                    },
                  }}
                  className="modern-scrollbar dataGrid"
                  rowHeight={35}
                  rows={columnDrivers}
                  getRowId={(row) => row.lead_id}
                  columns={mappedColumns}
                  disableColumnMenu={true}
                  onColumnWidthChange={handleColumnResize}
                  paginationMode="server"
                  rowCount={totalLeads}
                  pageSizeOptions={[5, 10, 25, 50]}
                  paginationModel={{ page, pageSize: rowsPerPage }}
                  onPaginationModelChange={(newModel) => {
                    if (newModel.page !== page) {
                      handleChangePage(null, newModel.page);
                    }
                    if (newModel.pageSize !== rowsPerPage) {
                      handleChangeRowsPerPage({ target: { value: newModel.pageSize } });
                    }
                  }}
                  disableVirtualization={true}
                />
              </DndProvider>
            </Box>
          ) : (
            <>
              <Box
                sx={{
                  height: 'calc(100vh - 218px)',
                  display: 'flex',
                  flexDirection: 'column',
                  pt: '60px',
                  alignItems: 'center',
                  overflow: 'auto',
                }}
              >
                {isNotFound ? (
                  <>
                    <Typography variant="h4" paragraph>
                      Not found
                    </Typography>
                    <Typography variant="body1">
                      No results found for{' '}
                      <Typography
                        component="span"
                        sx={{
                          fontWeight: 'bold',
                        }}
                      >
                        "{filterName}"
                      </Typography>{' '}
                      in the
                      <Typography
                        component="span"
                        sx={{
                          fontWeight: 'bold',
                        }}
                      >
                        {' '}
                        {currentSearchedStatus}
                      </Typography>{' '}
                      tab.
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography variant="h4" paragraph>
                      Nothing here
                    </Typography>

                    <Typography variant="body1">
                      No results found in the{' '}
                      <Typography
                        component="span"
                        sx={{
                          fontWeight: 'bold',
                        }}
                      >
                        {currentSearchedStatus}
                      </Typography>{' '}
                      tab. Currently you don't have any leads with this status.
                    </Typography>
                  </>
                )}
              </Box>
            </>
          )}
        </>
      ) : (
        <Box
          sx={{
            height: 'calc(100vh - 218px)',
            display: 'flex',
            flexDirection: 'column',
            pt: '60px',
            alignItems: 'center',
            overflow: 'auto',
          }}
        >
          <CircularProgress sx={{ color: '#028090' }} size={50} color="inherit" />
        </Box>
      )}
    </>
  );
};

export default DashboardTable;
