const handleFetchResponse = async (response) => {
  if (!response.ok) {
    const data = await response.json();
    throw new Error(`${data.message || response.statusText}`);
  }
  return response.json();
};

const useLoginAuth = async ({
  setIsLoggedIn,
  setUser,
  logInEmail,
  logInPassword,

  setFetchingDataLoader,
}) => {
  try {
    const companyID = await fetch('https://app.truckersflow.com/wp-json/data-api/v1/company_login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization:
          'Bearer DRNibve10j5QAItTgGMD9TQzieIGxyqKkrd9CCAfXtmvb9jKjUzOsQvOJeWnuFOFiVyEYgjHleJ5IxBii95jnBDrwo9whlM4R7sNUyUmYaKNwrDP1pqFzzUTJx2Gsi0o',
      },
      body: JSON.stringify({
        company_login: logInEmail,
        company_password: logInPassword,
      }),
    }).then(handleFetchResponse);

    const companyData = await fetch(`https://app.truckersflow.com/wp-json/data-api/v1/company/${companyID}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization:
          'Bearer DRNibve10j5QAItTgGMD9TQzieIGxyqKkrd9CCAfXtmvb9jKjUzOsQvOJeWnuFOFiVyEYgjHleJ5IxBii95jnBDrwo9whlM4R7sNUyUmYaKNwrDP1pqFzzUTJx2Gsi0o',
      },
    }).then(handleFetchResponse);

    setUser(companyData);
    localStorage.setItem('user', JSON.stringify(companyData));

    setIsLoggedIn(true);
    setFetchingDataLoader(false);
  } catch (error) {
    throw new Error(error.message || 'Login failed');
  }
};
export default useLoginAuth;
