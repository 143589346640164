import { Stack, Typography } from '@mui/material';
import './RowCell.scss';
export const RowCell = ({ onClick, children, capitalized = false, variant = 'string', ellipsis = false }) => {
  return (
    <Stack
      className="rowCell"
      direction="row"
      onClick={onClick}
      sx={{
        display: 'inline-block',
        cursor: 'pointer',
      }}
    >
      <Typography
        variant={variant}
        noWrap
        textTransform={capitalized ? 'capitalize' : 'none'}
        sx={{
          verticalAlign: 'middle',
          width: '100%',
          ...(ellipsis && {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }),
        }}
      >
        {children}
      </Typography>
    </Stack>
  );
};
