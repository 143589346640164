import { Box } from '@mui/material';
import { useDrag, useDrop } from 'react-dnd';

export const DraggableColumnHeader = ({ column, index, moveColumn }) => {
  const [{ isDragging }, drag] = useDrag({
    type: 'column',
    item: { index, label: column.headerName },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: 'column',
    drop: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveColumn(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
    collect: (monitor) => ({
      canDrop: monitor.canDrop(),
      isOver: monitor.isOver(),
    }),
  });

  return (
    <div
      ref={(node) => drag(drop(node))}
      style={{
        width: '100%',
        height: '100%',
        transition: 'transform 0.2s ease, opacity 0.2s ease',
      }}
    >
      <Box
        sx={{
          cursor: 'grab',
          opacity: isDragging ? 0.5 : 1,
          fontWeight: 'bold',
          padding: '8px',
          textAlign: 'center',
          width: '100%',
          height: '100%',
          alignContent: 'center',
        }}
      >
        {column.headerName}
      </Box>
    </div>
  );
};
